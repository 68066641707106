import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  max-width: 960px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.sizes.lvl4} ${({ theme }) => theme.sizes.lvl2};

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding: ${theme.sizes.lvl6} ${theme.sizes.lvl3};
  `}
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    padding: ${theme.sizes.lvl8} ${theme.sizes.lvl4};
  `}
  
  ${({ theme }) => theme.breakpoint('desktop')`
    max-width: 1279px;
    padding: ${theme.sizes.lvl8} ${theme.sizes.lvl6};
  `}
    ${({ theme }) => theme.breakpoint('medium')`
    max-width: 1440px;
    padding: ${theme.sizes.lvl8} ${theme.sizes.lvl8};
  `}
`;

interface ContainerComponentProps {
  children?: ReactNode;
  className?: string;
}

const ContainerComponent = ({
  children,
  className,
}: ContainerComponentProps) => (
  <Container className={className}>{children}</Container>
);

export { ContainerComponent as Container };
export default ContainerComponent;
